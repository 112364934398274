import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import sd from "../assets/images/sd.svg";
import sd2 from "../assets/images/sd2.svg";
import data from "../assets/images/data.svg";
import web from "../assets/images/web.svg";
import cloud from "../assets/images/cloud.svg";
import consultant from "../assets/images/consultant.svg";
import group from "../assets/images/group.svg";
import computers from "../assets/images/computers.svg";
import blockchain from "../assets/images/blockchain.svg";
import network from "../assets/images/network.svg";
import connect from "../assets/images/connect.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Home() {
  return (
    <>
      <div className="animate-fade-in  flex items-center justify-center mx-auto xl:w-full md:w-3/4 h-16 bg-blue-400">
        <p className="text-slate-100 md:text-lg xl:text-xl font-medium hidden md:block">
          RaxiTech Digitals Limited
          <span className="text-blue-900">
            {" "}
            I.T solutions without Hassle or Expenses
          </span>
        </p>
        <span className=" text-slate-100 text-lg font-medium md:hidden">
          {" "}
          I.T solutions without{" "}
          <span className="text-blue-900">Hassle or Expenses</span>
        </span>
      </div>
      <div className="md:mx-auto md:w-3/4 mx-2">
        <div className="flex my-5 rounded flex-row ">
          <div className="animate-slideInFromTop  w-1/2 flex flex-col items-center justify-center h-[23rem] ">
            <h1 className="text-blue-900 text-center text-3xl font-bold md:text-4xl 2xl:text-5xl">
              Digitize Your Idea
              <h4 className="text-left flex-none text-sm font-normal md:text-xl">
                Building the best of apps
              </h4>
            </h1>
          </div>
          <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: false,
            }}
            modules={[Autoplay]}
            className="mySwiper w-1/2 h-[23rem] z-0"
          >
            <SwiperSlide className=" flex flex-row items-center justify-center ">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Software <span className="text-[#438fe6]">Development</span>
              </h1>

              <img
                src={sd}
                className="h-[23rem] p-3 sm:p-9 md:p-12 lg:p-10 xl:p-8"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className=" flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Computer <span className="text-[#438fe6]">Networking</span>
              </h1>
              <img src={network} className=" h-[23rem]" alt="" />
            </SwiperSlide>
            <SwiperSlide className=" flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Software <span className="text-[#438fe6]">Development</span>
              </h1>
              <img
                src={sd2}
                className=" h-[23rem] p-3 sm:p-9 md:p-4 lg:p-10 xl:p-8"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className=" flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Data <span className="text-[#438fe6]">Management</span>
              </h1>
              <img src={data} className=" h-[23rem] p-3" alt="" />
            </SwiperSlide>
            <SwiperSlide className=" flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Website <span className="text-[#438fe6]">Development</span>
              </h1>
              <img src={web} className=" h-[23rem]  p-3" alt="" />
            </SwiperSlide>
            <SwiperSlide className=" flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                Cloud <span className="text-blue-400">Computing</span>
              </h1>
              <img
                src={cloud}
                className=" h-[23rem] sm:p-9 md:p-4 lg:p-8 xl:p-10"
                alt=""
              />
            </SwiperSlide>
            <SwiperSlide className="flex items-center justify-center">
              <h1 className="flex-none md:text-right absolute font-semibold text-blue-900 p-2 bottom-0 text-center md:right-0">
                I.T <span className="text-blue-400">Consultancy</span>
              </h1>
              <img
                src={consultant}
                className=" h-[23rem]  p-3 sm:p-9 md:p-6 lg:p-10 xl:p-10"
                alt=""
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="my-3 mt-10 ">
          <h1 className="my-2 text-blue-900 text-lg font-bold md:text-2xl 2xl:text-3xl">
            What we do
          </h1>
          <div className=" flex flex-row gap-1 justify-center items-center">
            <div className="w-12 h-[0.15rem] bg-blue-900 opacity-80 rounded"></div>
            <div className="w-full h-[0.15rem] bg-blue-200 rounded"></div>
          </div>
        </div>
        <div className="my-5 grid grid-cols-1 lg:grid-cols-2 justify-center items-center gap-12 md:gap-6 xl:gap-6 h-full">
          <div className="p-4 pb-5 flex flex-col justify-between item-start  h-fit shadow-md rounded">
            <div>
              <img
                src={group}
                className="w-20 h-20 mb-2 bg-[#8abff4] rounded-full"
                alt=""
              />
              <h1 className="mb-2 text-blue-900 text-left text-lg font-semibold md:text-xl 2xl:text-2xl">
                IT Consultancy
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start h-fit shadow-md rounded">
            <div>
              <img
                src={connect}
                className="w-20 h-20 mb-2 bg-[#e6e1db] rounded-full"
                alt=""
              />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                CCNA
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start h-fit shadow-md rounded">
            <div>
              <img
                src={computers}
                className="w-20 h-20 mb-2 bg-[#f8ebca] rounded-full"
                alt=""
              />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Procurement
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-[#D6E5FA] h-fit shadow-md rounded">
            <div>
              <img src={blockchain} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Web 3 Technology
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-white h-fit shadow-md rounded">
            <div>
              <img src={group} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left text-lg font-semibold md:text-xl 2xl:text-2xl">
                IT Consultancy
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-white h-fit shadow-md rounded">
            <div>
              <img src={connect} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                CCNA
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-white h-fit shadow-md rounded">
            <div>
              <img src={computers} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Procurement
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-white h-fit shadow-md rounded">
            <div>
              <img src={blockchain} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Web 3 Technology
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-gray-50 h-fit shadow-md rounded">
            <div>
              <img src={group} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left text-lg font-semibold md:text-xl 2xl:text-2xl">
                IT Consultancy
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-gray-50 h-fit shadow-md rounded">
            <div>
              <img src={connect} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                CCNA
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-gray-50 h-fit shadow-md rounded">
            <div>
              <img src={computers} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Procurement
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-gray-50 h-fit shadow-md rounded">
            <div>
              <img src={blockchain} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Web 3 Technology
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-[#eff8ff] h-fit shadow-md rounded">
            <div>
              <img src={group} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left text-lg font-semibold md:text-xl 2xl:text-2xl">
                IT Consultancy
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-[#eff8ff] h-fit shadow-md rounded">
            <div>
              <img src={connect} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                CCNA
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-[#eff8ff] h-fit shadow-md rounded">
            <div>
              <img src={computers} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Procurement
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
          <div className="p-4 pb-5 flex flex-col justify-between item-start bg-[#eff8ff] h-fit shadow-md rounded">
            <div>
              <img src={blockchain} className="w-20 h-20 mb-2" alt="" />
              <h1 className="mb-2 text-blue-900 text-left  text-lg font-semibold md:text-xl 2xl:text-2xl">
                Web 3 Technology
              </h1>
            </div>
            <div className="text-left">
              <p className="mb-3">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil
                eos nobis ipsa totam ad enim vitae, necessitatibus voluptas vero
                iusto quam laborum animi inventore beatae repellendus odio
                delectus. Odio necessitatibus, libero adipisci nam pariatur
                consectetur deserunt consequuntur ea ratione amet. Lorem ipsum
                dolor sit amet consectetur adipisicing elit. Pariatur,
                dignissimos perspiciatis itaque natus molestias dolorem odit?
                Distinctio assumenda repellendus eius Lorem ipsum dolor sit amet
                Lorem
              </p>
            </div>
            <button className="bg-blue-900 w-fit text-white px-3 py-1 rounded-sm">
              <a href="/">Read more</a>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
